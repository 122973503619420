import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import router from '@/router';
import Users from '@/services/Users.service.js';
import Subscriptions from '@/services/Subscriptions.service.js';
import Tenants from '@/services/Tenants.service.js';
import {roles} from './roles.js';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => {
  return window.history.replaceState(null, null, 'window.location.hostname');
};

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        failedAuthentication: false,
        user: {},
        userProfile: {},
        tenantProfile: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri,
      });

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          // console.log("returning to the app after authentication: ")

          // handle the redirect and retrieve tokens
          const {appState} = await this.auth0Client.handleRedirectCallback();

          // remove code and state from url
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        // get user info from auth0 (we need the email the user logged in with)
        this.user = await this.auth0Client.getUser();

        // Initialize our internal authentication state
        var authenticated = await this.auth0Client.isAuthenticated();

        // must check, otherwise routing to login after signing
        // out will not complete
        if (authenticated) {
          // get user profile and tenant profile based on the email address
          // the user is trying to log in with
          this.userProfile = await this.getUserProfile();

          const [tenantProfile, subscriptionStatus] = await Promise.all([
            this.getTenantProfile(),
            this.getSubscriptionStatusFromTenantUuid(),
          ]);

          this.tenantProfile = tenantProfile;
          if (!subscriptionStatus) {
            router.push('/pay/?uuid=' + this.tenantProfile.uuid);
          }

          // now we can only authenticate if a userprofile and tenant profile
          // was retrieved. If we don't check this and the person logging in
          // somehow has a mismatched or missing email to what is in the database
          // we can't authenticate! If this is not checked, the userProfile will
          // be blank and the queries will simply return all the users and info
          // since the tenant uuid is blank

          if (this.userProfile.tenant_uuid && this.tenantProfile.uuid) {
            this.isAuthenticated = authenticated;
            this.loading = false;

            // in this case there is a subscription but it has lapsed
            if (!subscriptionStatus.active) {
              // console.log('subscription check');
              router.push('/cancel');
            }
          } else {
            // otherwise, mark authentication as failed
            this.isAuthenticated = false;
            this.failedAuthentication = true;
            this.loading = false;
          }
        } else {
          // otherwise, mark authentication as failed
          this.isAuthenticated = false;
          this.failedAuthentication = true;
          this.loading = false;
        }
      }
    },
    methods: {
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },

      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o);
      },

      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },

      /** Gets the user profile from the database */
      async getUserProfile() {
        var accessToken = await this.getTokenSilently();
        var email = await this.user.email;
        return Users.getUserProfile(email, accessToken);
      },
      /** Gets the tenant profile from the database */
      async getTenantProfile() {
        if (this.userProfile && this.userProfile.tenant_uuid) {
          const accessToken = await this.getTokenSilently();
          return Tenants.getTenant(this.userProfile.tenant_uuid, accessToken);
        }
      },

      async reloadTenantProfile() {
        this.tenantProfile = await this.getTenantProfile();
      },

      async getTenantProfileByDomain() {
        const accessToken = await this.getTokenSilently();
        let currentHost = await window.location.hostname;
        let urlParts = currentHost.split('.');
        let currentSubDomain = urlParts[0];
        currentSubDomain = currentSubDomain.toLowerCase(); //force lowercase
        // console.log("Attempting to get tenant profile using subdomain");
        return Tenants.getTenantByDomain(currentSubDomain, accessToken);
      },

      async isSystemAdmin() {
        await this.userProfile;
        if (this.userProfile) {
          if (this.userProfile.role == 'system_admin') {
            // console.log("system admin time!")
            return true;
          }
        }
        // console.log(" not system admin!")
        return false;
      },

      isAllowed(object, action) {
        // console.log( "allow request: ", object, action );
        if (this.userProfile) {
          var currentRole = roles[this.userProfile.role];

          // console.log( "current role: ", currentRole[object] );

          if (currentRole && currentRole.hasOwnProperty(object)) {
            if (currentRole[object].hasOwnProperty(action)) {
              if (currentRole[object][action] == 'allow') {
                return true;
              }
            }
          }
        }
        // console.log(this.isAuthenticated);

        return false;
      },

      async getSubscriptionStatusFromTenantUuid() {
        if (this.userProfile && this.userProfile.tenant_uuid) {
          const accessToken = await this.getTokenSilently();
          try {
            const subscription =
              await Subscriptions.getSubscriptionStatusFromTenantUuid(
                this.userProfile.tenant_uuid,
                accessToken
              );
            return subscription;
          } catch (err) {
            return undefined;
          }
        }
      },
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
