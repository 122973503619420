import {system_admin} from './role_sa';
import {admin} from './role_ta';
import {crew} from './role_crew';

const roles = {
  system_admin: system_admin,
  admin: admin,
  crew: crew,
};

export {roles};
