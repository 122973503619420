// src/services/Tenants.service.js

import axios from 'axios';
import {uuid} from 'vue-uuid';

export default {
  blankTenant() {
    return {
      uuid: uuid.v4(),
      tenant_name: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      contact_phone: '',
      contact_email: '',
      account_status: 'Active',
      subscription_start_date: new Date().toISOString().substr(0, 10),
      subscription_end_date: new Date(2016, 9, 16).toISOString().substr(0, 10),
      subscription_type: '',
      subscription_status: 'Not Active',
      tenant_url: '',
      currency_symbol: '$',
      currency_abbr: 'USD',
      default_hourly_rate: '0',
      invoice_terms: '',
      invoice_notes: '',
      invoice_name: '',
      invoice_address: '',
      invoice_city: '',
      invoice_state: '',
      invoice_postal_code: '',
      logo: '',
    };
  },
  blankCrew() {
    return {
      uuid: uuid.v4(),
      name: '',
      status: '',
      foreman_uuid: '',
      tenant_uuid: '',
    };
  },
  created() {
    // handle api call errors
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
      }
    );
  },

  // *****************************************************/
  //                    GET SINGLE
  // *****************************************************/
  async getTenant(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_tenants/tenants/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getSingleTenantSpecies(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_tenants/tenant_species/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getTenantByDomain(domain, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_tenants/tenants/all',
      params: {tenant_url: domain},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data[0];
  },

  async getEstimatorUserDetails(uuid, accessToken) {
    let result = await axios({
      method: 'GET',
      url: '/service_tenants/tenants/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result.data;
  },

  async getQuickbooksAuthentication(uuid, accessToken) {
    const res = await axios({
      method: 'GET',
      url: '/service_tenants/quickbooks/authenticate/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': 'https://accounts.intuit.com/',
      },
    });
    return res.data.uri;
  },

  async revokeQuickbooksAuthentication(uuid, accessToken) {
    const res = await axios({
      method: 'GET',
      url: '/service_tenants/quickbooks/revoke/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': 'https://accounts.intuit.com/',
      },
    });

    return !!res;
  },

  async getQuickbooksTokenStatus(uuid, accessToken) {
    const res = await axios({
      method: 'GET',
      url: '/service_tenants/quickbooks/token_status/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res.data;
  },

  async refreshQuickbooksToken(uuid, accessToken) {
    const res = await axios({
      method: 'GET',
      url: '/service_tenants/quickbooks/refresh/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ TENANTS ]
  // NOTE: UNSECURED! Needed for estimate proposal
  async getTaxInfo(uuid) {
    let res = await axios({
      method: 'GET',
      url: `/service_tenants/tenants/tax/${uuid}`,
    });
    return res.data;
  },

  // [ TENANTS ]
  // NOTE: UNSECURED! Needed for estimate proposal
  async getSettingsInfo(uuid) {
    let res = await axios({
      method: 'GET',
      url: `/service_tenants/tenants/settings/${uuid}`,
    });
    return res.data;
  },

  // *****************************************************/
  //                    UPDATE
  // *****************************************************/

  async updateTenant(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_tenants/tenants/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return !!res;
  },

  async updateTenantSpecies(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_tenants/tenant_species/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return !!res;
  },

  async updateMasterSpecies(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_tenants/master_species/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return !!res;
  },

  // *****************************************************/
  //                    GET BY PARAMS
  // *****************************************************/
  async getTenants(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_tenants/tenants/all',
      params: params,
      headers: {Authorization: `Bearer ${accessToken}`},
    });
    return res.data;
  },

  // [ TENANT SPECIES ]
  // NOTE: UNSECURED! Needed for estimate proposal
  async getTenantSpecies(tenant_uuid, params) {
    let res = await axios({
      method: 'GET',
      url: `/service_tenants/tenant_species/by_tenant/${tenant_uuid}`,
      params: params,
    });
    return res.data;
  },

  async getMasterSpecies(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_tenants/master_species/all',
      params: params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ TENANTS ]
  // NOTE: UNSECURED! Needed for onboarding
  async checkIfUrlIsTaken(params) {
    let res = await axios({
      method: 'GET',
      url: '/service_tenants/tenants/url',
      params: params,
    });
    return res.data;
  },

  // [ TENANTS ]
  // NOTE: UNSECURED! Needed for onboarding
  async checkIfTenantNameIsTaken(tenant_name) {
    let response = await axios({
      method: 'GET',
      url: '/service_tenants/tenants/checkIfTenantNameIsTaken',
      params: {tenant_name: tenant_name},
    });
    return response.data;
  },

  // *****************************************************/
  //                    GET BY DATA
  // *****************************************************/
  // [ MASTER SPECIES ]
  // NOTE: UNSECURED! Needed for estimate link
  async getMasterSpeciesBySpeciesLookup(data) {
    let res = await axios({
      method: 'POST',
      url: '/service_tenants/master_species/speciesLookup',
      data: data,
    });
    return res.data;
  },

  // *****************************************************/
  //                   UPLOAD
  // *****************************************************/
  async uploadTenantLogo(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_tenants/tenants/uploadLogo',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // *****************************************************/
  //                    CREATE
  // *****************************************************/

  // [ TENANTS ] NOTE: UNSECURED! Needed for onboarding
  async createTenant(data) {
    if (!data) {
      console.log('no tenant data sent!');
      return;
    }

    if (data.tenant_url) {
      data.tenant_url = data.tenant_url.toLowerCase();
    }

    let res = await axios({
      method: 'POST',
      url: '/service_tenants/tenants/create',
      data: data,
    });

    return !!(res && res.data);
  },

  // createCrew allows a tenant to create a new crew
  async createCrew(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_tenants/crews/create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async createQuickbooksClient(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_tenants/quickbooks/add_client',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res.data;
  },
};
