// src/services/Users.service.js

import axios from 'axios';
import {uuid} from 'vue-uuid';

export default {
  blankUser() {
    var user = {
      uuid: uuid.v4(),
      tenant_uuid: '',
      role: '',
      name: '',
      email: '',
      password: '',
      phone: '',
      status: 'Active',
    };
    return user;
  },
  blankWorkCategory() {
    return {
      uuid: uuid.v4(),
      tenant_uuid: '',
      name: '',
      catalog: '',
      status: 'Active',
      pricing_method: '',
    };
  },
  blankWorkType() {
    return {
      uuid: uuid.v4(),
      tenant_uuid: null,
      workcategory_uuid: null,
      name: '',
      status: 'Active',
      rate: '',
    };
  },
  created() {
    // handle api call errors
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
      }
    );
  },

  // *****************************************************/
  //                      GET BY UUID
  // *****************************************************/

  // [ USERS ]
  async getUser(uuid, access_token) {
    let res = await axios({
      method: 'GET',
      url: `/service_users/users/${uuid}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  },

  // [ USERS ]
  // NOTE: UNSECURED! USED FOR PUBLIC ESTIMATE APPROVAL LINK
  async getEstimator(uuid) {
    let res = await axios({
      method: 'GET',
      url: `/service_users/users/estimator/${uuid}`,
    });
    return res.data;
  },

  async getWorkCategory(uuid, access_token) {
    let res = await axios({
      method: 'GET',
      url: `/service_users/work_categories/${uuid}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  },

  async getUserProfile(email, access_token) {
    // console.log(email);
    let res = await axios({
      method: 'GET',
      url: '/service_users/users/findByEmail',
      params: {email: email},
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    return res.data;
  },

  async checkIfEmailIsTaken(email) {
    let response = await axios({
      method: 'GET',
      url: '/service_users/users/checkIfEmailIsTaken',
      params: {email: email},
    });
    return response.data;
  },

  // *****************************************************/
  //                     UPDATE
  // *****************************************************/

  // [ USER ]
  async updateUser(uuid, data, access_token) {
    // post new user to database
    var response = await axios({
      method: 'PUT',
      url: `/service_users/users/${uuid}`,
      data: data,
      headers: {Authorization: `Bearer ${access_token}`},
    });

    if (response) {
      return true;
    } else {
      return false;
    }
  },

  // [ WORK CATEGORIES ]
  async updateWorkCategory(uuid, data, access_token) {
    // post new user to database
    var response = await axios({
      method: 'PUT',
      url: `/service_users/work_categories/${uuid}`,
      data: data,
      headers: {Authorization: `Bearer ${access_token}`},
    });

    if (response) {
      return true;
    } else {
      return false;
    }
  },

  // [ WORK CATEGORIES ]
  async updateWorkType(uuid, data, access_token) {
    // post new user to database
    var response = await axios({
      method: 'PUT',
      url: `/service_users/work_types/${uuid}`,
      data: data,
      headers: {Authorization: `Bearer ${access_token}`},
    });

    if (response) {
      return true;
    } else {
      return false;
    }
  },

  async getUsersByTenant(tenant_uuid, access_token) {
    let res = await axios({
      method: 'GET',
      url: `/service_users/users/findByTenant/${tenant_uuid}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  },

  // *****************************************************/
  //                      GET BY PARAMS
  // *****************************************************/

  // [ WORK CATEGORIES ]
  async getWorkCategories(params, access_token) {
    let res = await axios({
      method: 'GET',
      url: '/service_users/work_categories/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  },

  // [ WORK TYPES ]
  async getWorkTypes(params, access_token) {
    let res = await axios({
      method: 'GET',
      url: '/service_users/work_types/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  },

  // async getUsers(params, access_token) {
  //   let res = await axios({
  //     method: 'GET',
  //     url: "/service_users/users/all",
  //     params: params || {},
  //     headers: {
  //       Authorization: `Bearer ${access_token}`
  //     }
  //   });
  //   return res.data;
  // },

  // *****************************************************/
  //                     CREATE
  // *****************************************************/

  // [ USERS ]
  // NOTE: UNSECURED! this is needed for onboarding
  async createUser(data, access_token) {
    // add new uuid
    if (!data.uuid) {
      data.uuid = uuid.v4();
    }

    // post new user to database
    var response = await axios({
      method: 'POST',
      url: '/service_users/users/create',
      data: data,
      headers: {Authorization: `Bearer ${access_token}`},
    });

    if (response) {
      return true;
    } else {
      return false;
    }
  },

  // [ WORK CATEGORIES ]
  // NOTE: UNSECURED! this is needed for onboarding
  async createWorkCategory(data) {
    // add new uuid
    if (!data.uuid) {
      data.uuid = uuid.v4();
    }

    // post new user to database
    var response = await axios({
      method: 'POST',
      url: '/service_users/work_categories/create',
      data: data,
    });

    if (response) {
      return true;
    } else {
      return false;
    }
  },

  // [ WORK TYPES ]
  // NOTE: UNSECURED! this is needed for onboarding
  async createWorkType(data) {
    // add new uuid
    if (!data.uuid) {
      data.uuid = uuid.v4();
    }

    // post new user to database
    var response = await axios({
      method: 'POST',
      url: '/service_users/work_types/create',
      data: data,
    });

    if (response) {
      return true;
    } else {
      return false;
    }
  },
};
