// import '@babel/polyfill'
// import "core-js/stable";
// import "regenerator-runtime/runtime";
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import EventBus from '@/EventBus';
import '@/styles/main.scss';
import '@mdi/font/css/materialdesignicons.css';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import ConfigPlugin from './plugins/config';

// import auth0 settings (this file is not included in the repo, and must
// be created and set for each server individually)

// Import the plugin here
import {Auth0Plugin} from '@/auth';

// Vue.use(Vuetify)
Vue.prototype.$events = EventBus;

const vuetifyOpts = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#004370',
        secondary: '#666665',
        success: '#87c540',
        accent: '#e8bb00',
        approved: '#87c540',
        declined: '#ff5252',
        'in-progress': '#2196f3',
        save: '#2196f3',
        submit: '#004370',

        pink: '#FF69B4',

        'button-primary': '#2FA3F2',
        'button-secondary': '#F3F3F3',
        'button-tertiary': '#004370',

        'phase-red': '#EA543E',
        'phase-orange': '#E8BB00',
        'phase-yellow': '#ECFF2C',
        'phase-green': '#87C540',
        'phase-gray': '#F3F3F3',
        'phase-white': '#FFFFFF',
      },
      dark: {
        primary: '#004370',
        secondary: '#666665',
        pink: '#FA8320',
      },
    },
  },
};

// sets the app in development mode
Vue.config.productionTip = false;

Vue.use(Vuetify);

fetch('/config.json')
  .then((response) => response.json())
  .then((config) => {
    Vue.use(ConfigPlugin, {config});
    Vue.use(Auth0Plugin, {
      ...config.client_configurations.auth0,
      onRedirectCallback: (appState) => {
        router.push(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.hostname
        );
      },
    });

    new Vue({
      router,
      // store,
      render: (h) => h(App),
      vuetify: new Vuetify(vuetifyOpts),
    }).$mount('#app');
  })
  .catch((err) => {
    console.log('oh snap!', err);
  });
