import {getInstance} from './index';

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // console.log( "authGuard" )

    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      // if (!window.location.hostname.includes(authService.tenantProfile.tenant_url)) {

      //   window.location.hostname = authService.tenantProfile.tenant_url + '.' + window.location.hostname
      // }
      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({appState: {targetUrl: to.fullPath}});
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export const roleGuard = (object, action, path, next) => {
  const authService = getInstance();

  const fn = () => {
    // console.log( "roleGuard" )
    // console.log( authService.userProfile, authService.userProfile.role, authService.isAllowed( object, path ), object, path )

    if (authService.userProfile && authService.userProfile.role) {
      var res = authService.isAllowed(object, action);
      if (res) {
        next();
      } else {
        next('/404');
      }
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
