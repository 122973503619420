import Vue from 'vue';
import VueRouter from 'vue-router';

// authentication guard
import {authGuard, roleGuard} from '@/auth/authGuard';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/onboard',
      name: 'Onboard',
      component: () => import('@/views/Onboard.vue'),
      props: true,
    },
    {
      path: '/success',
      name: 'Success',
      component: () => import('@/views/StripeSuccess.vue'),
      props: true,
    },
    {
      path: '/cancel',
      name: 'Cancel',
      component: () => import('@/views/StripeCancel.vue'),
      props: true,
    },
    {
      path: '/error',
      name: 'Error',
      component: () => import('@/views/GenericError.vue'),
      props: true,
    },
    {
      path: '/pay',
      name: 'Pay',
      component: () => import('@/views/StripePaymentSelector.vue'),
      props: true,
    },
    {
      path: '/approve/:uuid',
      name: 'EstimateProposalPublic',
      component: () =>
        import('@/views/estimates/AppEstimateProposalPublic.vue'),
      props: true,
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import('@/views/404.vue'), //Vue component
      //   // //beforeEnter: authGuard  no access without login
      //   // //beforeEnter: authGuard,  no access without login
    },
    {
      path: '/',
      name: 'home',
      beforeEnter: authGuard, // no access without login
      component: () => import('@/views/Home.vue'),
      children: [
        {
          path: '',
          name: 'myForest',
          component: () => import('@/views/myForest/MyForest.vue'),
          props: true,
          meta: {permission: {object: 'myForest', action: 'view'}},
        },

        /*****************************************************************/
        /*                          CLIENTS                              */
        /*****************************************************************/
        {
          path: '/clients',
          component: () => import('@/views/Clients.vue'),
          children: [
            {
              path: '',
              name: 'ClientList',
              component: () => import('@/views/clients/AppClientList.vue'),
              props: true,
              meta: {permission: {object: 'clients', action: 'list'}},
            },
            {
              path: 'view/:uuid',
              name: 'ClientView',
              component: () => import('@/views/clients/AppClientView.vue'),
              props: true,
              meta: {permission: {object: 'clients', action: 'view'}},
            },
            {
              path: 'edit/:uuid',
              name: 'ClientEdit',
              component: () => import('@/views/clients/AppClientEdit.vue'),
              props: true,
              meta: {permission: {object: 'clients', action: 'edit'}},
            },
            {
              path: 'create',
              name: 'ClientCreate',
              component: () => import('@/views/clients/AppClientCreate.vue'),
              props: true,
              meta: {permission: {object: 'clients', action: 'create'}},
            },
            {
              path: 'view/:uuid/ServiceRequestCreate',
              name: 'ClientServiceRequestCreate',
              component: () =>
                import('@/views/clients/AppClientServiceRequestCreate.vue'),
              props: true,
              meta: {
                permission: {object: 'servicerequests', action: 'create'},
              },
            },
            {
              path: 'view/:uuid/newJobSite',
              name: 'ClientJobSiteCreate',

              component: () =>
                import('@/views/clients/AppClientJobSiteCreate.vue'),
              props: true,
              meta: {permission: {object: 'jobSites', action: 'create'}},
            },
          ],
        },

        /*****************************************************************/
        //                         ESTIMATES
        /*****************************************************************/
        {
          path: '/estimates',
          component: () => import('@/views/Estimates.vue'),
          children: [
            {
              path: '',
              name: 'EstimateList',
              component: () => import('@/views/estimates/AppEstimateList.vue'),
              props: true,
              meta: {permission: {object: 'estimates', action: 'list'}},
            },
            {
              path: 'view/:uuid',
              name: 'EstimateView',
              component: () => import('@/views/estimates/AppEstimateView.vue'),
              props: true,
              meta: {permission: {object: 'estimates', action: 'view'}},
            },
            {
              path: 'proposal/:uuid',
              name: 'EstimateProposal',
              component: () =>
                import('@/views/estimates/AppEstimateProposal.vue'),
              props: true,
              meta: {permission: {object: 'estimates', action: 'view'}},
            },
          ],
        },

        /*****************************************************************/
        //                         TENANTS
        /*****************************************************************/

        {
          path: '/tenants',
          component: () => import('@/views/Tenants.vue'),
          children: [
            {
              path: '',
              name: 'TenantList',

              component: () => import('@/views/tenants/AppTenantList.vue'),
              props: true,
              meta: {permission: {object: 'tenants', action: 'list'}},
            },
            {
              path: 'view/:uuid',
              name: 'TenantView',
              component: () => import('@/views/tenants/AppTenantView.vue'),
              props: true,
              meta: {permission: {object: 'tenants', action: 'view'}},
            },
            {
              path: 'edit/:uuid',
              name: 'TenantEdit',
              component: () => import('@/views/tenants/AppTenantEdit.vue'),
              props: true,
              meta: {permission: {object: 'tenants', action: 'edit'}},
            },
            {
              path: 'create',
              name: 'TenantCreate',

              component: () => import('@/views/tenants/AppTenantCreate.vue'),
              props: true,
              meta: {permission: {object: 'tenants', action: 'create'}},
            },
          ],
        },

        /*****************************************************************/
        //                      WORK ORDERS
        /*****************************************************************/
        {
          path: '/workorders',
          component: () => import('@/views/WorkOrders.vue'),
          children: [
            {
              path: '',
              name: 'WorkOrderList',
              component: () =>
                import('@/views/workorders/AppWorkOrderList.vue'),
              props: true,
              meta: {permission: {object: 'workorders', action: 'list'}},
            },
            {
              path: 'view/:uuid',
              name: 'WorkOrderView',
              component: () =>
                import('@/views/workorders/AppWorkOrderView.vue'),
              props: true,
              meta: {permission: {object: 'workorders', action: 'view'}},
            },
          ],
        },
        /*****************************************************************/
        //                    CATALOGS (includes workcategories)
        /*****************************************************************/
        {
          path: '/catalog',
          component: () => import('@/views/Tenants.vue'),
          children: [
            {
              path: '',
              name: 'Catalog',
              component: () => import('@/views/catalog/AppCatalogList.vue'),
              props: true,
              meta: {permission: {object: 'workcategories', action: 'list'}},
            },
            {
              path: 'workcategory/edit/:uuid',
              name: 'WorkCategoryEdit',
              component: () =>
                import('@/views/catalog/AppWorkCategoryEdit.vue'),
              props: true,
              meta: {permission: {object: 'workcategories', action: 'edit'}},
            },
            {
              path: 'workcategory/view/:uuid',
              name: 'WorkCategoryView',
              component: () =>
                import('@/views/catalog//AppWorkCategoryView.vue'),
              props: true,
              meta: {permission: {object: 'workcategories', action: 'view'}},
            },
          ],
        },

        /*****************************************************************/
        //                      SERVICE REQUESTS
        /*****************************************************************/
        {
          path: '/serviceRequests',

          component: () => import('@/views/ServiceRequests.vue'),
          children: [
            {
              path: '',
              name: 'ServiceRequestList',
              component: () =>
                import('@/views/servicerequests/AppServiceRequestList.vue'),
              props: true,
              meta: {permission: {object: 'servicerequests', action: 'list'}},
            },
            {
              path: 'view/:uuid',
              name: 'ServiceRequestView',
              component: () =>
                import('@/views/servicerequests/AppServiceRequestView.vue'),
              props: true,
              meta: {
                permission: {object: 'servicerequests', action: 'view'},
              },
            },
            {
              path: 'edit/:uuid',
              name: 'ServiceRequestEdit',
              component: () =>
                import('@/views/servicerequests/AppServiceRequestEdit.vue'),
              props: true,
              meta: {
                permission: {object: 'servicerequests', action: 'edit'},
              },
            },
            {
              path: 'create',
              name: 'ServiceRequestCreate',
              component: () =>
                import('@/views/servicerequests/AppServiceRequestCreate.vue'),
              meta: {
                permission: {object: 'servicerequests', action: 'create'},
              },
            },
          ],
        },

        /*****************************************************************/
        //                           JOBS
        /*****************************************************************/
        {
          path: '/jobs',
          component: () => import('@/views/Jobs.vue'),
          children: [
            {
              path: '',
              name: 'JobList',
              component: () => import('@/views/jobs/AppJobList.vue'),
              props: true,
              meta: {permission: {object: 'jobs', action: 'list'}},
            },
            {
              path: 'view/:uuid',
              name: 'JobView',
              component: () => import('@/views/jobs/AppJobView.vue'),
              props: true,
              meta: {permission: {object: 'jobs', action: 'view'}},
            },
          ],
        },

        /*****************************************************************/
        //                           INVOICES
        /*****************************************************************/
        {
          path: '/invoices',

          component: () => import('@/views/Invoices.vue'),
          children: [
            {
              path: '',
              name: 'InvoiceList',
              component: () => import('@/views/invoices/AppInvoiceList.vue'),
              props: true,
              meta: {permission: {object: 'invoices', action: 'list'}},
            },
            {
              path: 'view/:uuid',
              name: 'InvoiceView',
              component: () => import('@/views/invoices/AppInvoiceView.vue'),
              props: true,
              meta: {permission: {object: 'invoices', action: 'view'}},
            },
          ],
        },
        /*****************************************************************/
        //                        JOB SITES
        /*****************************************************************/
        {
          path: '/jobSites',
          component: () => import('@/views/JobSites.vue'),
          children: [
            {
              path: 'edit/:uuid',
              name: 'JobSiteEdit',
              component: () => import('@/views/jobsites/AppJobSiteEdit.vue'),
              props: true,
              meta: {permission: {object: 'jobSites', action: 'edit'}},
            },
            {
              path: 'view/:uuid',
              name: 'JobSiteView',
              component: () => import('@/views/jobsites/AppJobSiteView.vue'),
              props: true,
              meta: {permission: {object: 'jobSites', action: 'view'}},
            },
          ],
        },
        /*****************************************************************/
        //                         USERS
        /*****************************************************************/
        {
          path: '/users',
          component: () => import('@/views/Users.vue'),
          children: [
            {
              path: '',
              name: 'UserList',
              component: () => import('@/views/users/AppUserList.vue'),
              props: true,
              meta: {permission: {object: 'users', action: 'list'}},
            },
          ],
        },

        /*****************************************************************/
        //                       CREWS
        /*****************************************************************/

        {
          path: '/crews',
          component: () => import('@/views/Crews.vue'),
          children: [
            {
              path: '',
              name: 'CrewList',
              component: () => import('@/views/crews/AppCrewList.vue'),
              props: true,
              meta: {permission: {object: 'crews', action: 'list'}},
            },
            {
              path: 'view/:uuid',
              name: 'CrewView',
              component: () => import('@/views/crews/AppCrewView.vue'),
              props: true,
              meta: {permission: {object: 'crews', action: 'view'}},
            },
            {
              path: 'edit/:uuid',
              name: 'CrewEdit',
              component: () => import('@/views/crews/AppCrewEdit.vue'),
              props: true,
              meta: {permission: {object: 'crews', action: 'edit'}},
            },
            {
              path: 'create',
              name: 'CrewCreate',
              component: () => import('@/views/crews/AppCrewCreate.vue'),
              meta: {permission: {object: 'crews', action: 'create'}},
            },
          ],
        },
        /*****************************************************************/
        //                           SCHEDULING
        /*****************************************************************/
        {
          path: '/scheduling',
          component: () => import('@/views/Schedule.vue'),
          children: [
            {
              path: '',
              name: 'ScheduleView',
              component: () => import('@/views/schedule/AppScheduleView.vue'),
              props: true,
              meta: {permission: {object: 'schedules', action: 'view'}},
            },
          ],
        },

        /*****************************************************************/
        //                           SPECIES
        /*****************************************************************/

        {
          path: '/species',
          component: () => import('@/views/Species.vue'),
          children: [
            {
              path: '',
              name: 'SpeciesList',
              component: () => import('@/views/species/AppSpeciesList.vue'),
              props: true,
              meta: {permission: {object: 'species', action: 'list'}},
            },
            {
              // both regular species view and admin view use the same route/component
              path: 'view/:uuid',
              name: 'SpeciesView',
              component: () => import('@/views/species/AppSpeciesView.vue'),
              props: true,
              meta: {permission: {object: 'species', action: 'view'}},
            },
            {
              path: 'edit/:uuid',
              name: 'SpeciesEdit',
              component: () => import('@/views/species/AppSpeciesEdit.vue'),
              props: true,
              meta: {permission: {object: 'species', action: 'edit'}},
            },
          ],
        },

        /*****************************************************************/
        //                        ADMIN SPECIES
        /*****************************************************************/

        {
          path: '/speciesAdmin',
          component: () => import('@/views/Species.vue'),
          children: [
            {
              path: '',
              name: 'SpeciesAdminList',
              component: () =>
                import('@/views/species/AppSpeciesAdminList.vue'),
              props: true,
              meta: {permission: {object: 'speciesadmin', action: 'list'}},
            },
            {
              // both regular species view and admin view use the same route/component
              path: 'view/:uuid',
              name: 'AdminSpeciesView',
              component: () => import('@/views/species/AppSpeciesView.vue'),
              props: true,
              meta: {permission: {object: 'speciesadmin', action: 'view'}},
            },
            {
              path: 'edit/:uuid',
              name: 'AdminSpeciesEdit',
              component: () => import('@/views/species/AppSpeciesEdit.vue'),
              props: true,
              meta: {permission: {object: 'speciesadmin', action: 'edit'}},
            },
          ],
        },

        /*****************************************************************/
        //                        TENANT SETTINGS
        /*****************************************************************/
        {
          path: '/settings',
          name: 'SettingsView',
          component: () => import('@/views/settings/AppSettingsView.vue'),
          props: true,
          meta: {permission: {object: 'tenantsettings', action: 'edit'}},
        },

        {
          path: '/tools',
          name: 'Tools',
          component: () => import('@/components/tools/Tools.vue'),
          props: true,
          meta: {permission: {object: 'tools', action: 'edit'}},
        },
        {
          path: '/seedDB',
          name: 'SeedDB',
          component: () => import('../views/SeedDB.vue'),
          props: true,
          meta: {permission: {object: 'seed', action: 'edit'}},
        },

        // not currently functional
        // {
        //   path: "/notifications",
        //   name: "NotificationsList",
        //   component: () =>
        //     import( "@/components/notifications/NotificationsList.vue" ),
        //   props: true
        // },
      ],
    },
  ],
});

// called after beforeEnter check (which will verify logging in)
// and before each route is resolved. For public routes, beforeEnter
// is not called and since meta permissions are not set, this function
// will just let it route through normally
router.beforeResolve((to, from, next) => {
  if (to.meta && to.meta.permission) {
    // verify user role permissions to route to view
    roleGuard(
      to.meta.permission.object,
      to.meta.permission.action,
      to.meta.permission.path,
      next
    );
  } else {
    // public route, let it through
    next();
  }
});

export default router;
