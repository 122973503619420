const admin = {
  tenants: {
    view: 'deny',
    edit: 'deny',
    list: 'deny',
    create: 'deny',
  },
  users: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
  },
  clients: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
  },
  estimates: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
    decline: 'allow',
  },
  servicerequests: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
  },
  dashboard: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
  },
  myForest: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
  },
  schedules: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
  },
  workorders: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
    assign: 'allow',
    schedule: 'allow',
    amount: 'allow',
  },
  jobs: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
  },
  jobSites: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
  },
  workcategories: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
  },
  worktypes: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
  },
  invoices: {
    view: 'allow',
    edit: 'allow',
    list: 'allow',
    create: 'allow',
  },
  trees: {
    edit: 'allow',
    create: 'allow',
    view: 'allow',
  },
  crews: {
    edit: 'allow',
    create: 'allow',
    view: 'allow',
    list: 'allow',
  },
  species: {
    edit: 'allow',
    create: 'allow',
    view: 'allow',
    list: 'allow',
  },
  speciesadmin: {
    edit: 'deny',
    create: 'deny',
    view: 'deny',
    list: 'deny',
  },
  tenantsettings: {
    edit: 'allow',
    create: 'allow',
    view: 'allow',
    list: 'allow',
  },
  catalog: {
    edit: 'allow',
    create: 'allow',
    view: 'allow',
    list: 'allow',
  },
  subscription: {
    edit: 'allow',
    create: 'allow',
    view: 'allow',
    list: 'allow',
  },
  uploadClientRecords: {
    edit: 'allow',
    create: 'allow',
    view: 'allow',
    list: 'allow',
  },
  tools: {
    edit: 'allow',
  },
  seed: {
    edit: 'deny',
  },
};

export {admin};
