<template>
  <v-app id="app">
    <!-- 
      this is the outer router view. This will not require authentication and
      display the home page, onboarding, and estimate previews. The home page has its
      own router, which will determine if authentication is completed and the user is
      able to view the inner routes 
    -->
    <router-view />
  </v-app>
</template>

<script lang="js">
  export default {
    name: 'app',
    components: {},
    watch: {},
    methods: {}
  }
</script>

<style lang="scss">
  a {
    text-decoration: none;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  .v-application .button-primary {
    color: white !important;
  }

  .v-application .button-tertiary {
    color: white !important;
  }
</style>
