import axios from 'axios';

export default {
  created() {
    // handle api call errors
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
      }
    );
  },

  // *****************************************************/
  //                      GET BY TENANT UUID
  // *****************************************************/

  // [ SUBSCRIPTIONS ]
  async getSubscriptionStatusFromTenantUuid(tenant_uuid, access_token) {
    console.log('getSubscriptionStatusFromTenantUuid');
    console.log(tenant_uuid);
    let res = await axios({
      method: 'GET',
      url: `/service_system/subscriptions/subscriptionStatus/byTenant/${tenant_uuid}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  },
};
